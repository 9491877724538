@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

/*===== GOOGLE FONTS =====*/

/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  --first-color: #da2535;
  --first-color-alt: #c42130;
  --first-color-send: #de3b49;
  --title-color: #161212;
  --text-color: #5b5757;
  --text-color-light: #8f8a8a;
  --body-color: #fefbfb;
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;
  --biggest-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;

  /*========== Font weight ==========*/
  --font-semi-bold: 600;
  --font-bold: 700;

  /*========== Margenes ==========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
  /*========== z index ==========*/

  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 3rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
}

/*========== BASE ==========*/
*,
::before,
::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/*========== Variables Dark theme ==========*/
body.dark-theme {
  --first-color-send: #161212;
  --title-color: #f3f1f1;
  --text-color: #d1c7c8;
  --body-color: #251d1e;
  --container-color: #302728;
  --container-color__2: #f3f1f1;
  --text-color__2: #5b5757;
}

/*========== Button Dark/Light ==========*/
.change-theme {
  position: absolute;
  right: 1.5rem;
  top: 2.2rem;
  display: flex;
  color: #161212;
  color: var(--title-color);
  font-size: 2rem;
  cursor: pointer;
}

body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  background-color: #fefbfb;
  background-color: var(--body-color);
  color: #5b5757;
  color: var(--text-color);
  line-height: 1.6;
}

h1,
h2,
h3,
ul,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  color: #161212;
  color: var(--title-color);
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*========== CLASS CSS ==========*/
.section {
  padding: 4rem 0 2rem;
}

.section-title,
.section-title-center {
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  color: #161212;
  color: var(--title-color);
  text-align: center;
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-3);
}

/*========== LAYOUT ==========*/
.l-main {
  overflow: hidden;
}

.bd-container {
  max-width: 968px;
  width: calc(100% - 3rem);
  margin-left: 1.5rem;
  margin-left: var(--mb-3);
  margin-right: 1.5rem;
  margin-right: var(--mb-3);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  background-color: #fefbfb;
  background-color: var(--body-color);
}

/*========== NAV ==========*/
.nav {
  height: 3rem;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    padding: 2.5rem 0 0;
    text-align: center;
    background-color: #fefbfb;
    background-color: var(--body-color);
    transition: 0.4s;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    z-index: 100;
    z-index: var(--z-fixed);
  }
}

.nav__item {
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-3);
}

.nav__link,
.nav__logo,
.nav__toggle {
  color: #161212;
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__logo:hover {
  color: #da2535;
  color: var(--first-color);
}

.nav__logo {
  display: flex;
  height: 100%;
  padding: 5px;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.nav__logo > img {
  height: 100%;
}

.nav__link {
  transition: 0.3s;
}

.nav__link:hover {
  color: #da2535;
  color: var(--first-color);
}

.nav__toggle {
  font-size: 1.3rem;
  cursor: pointer;
}

/* Show menu */
.show-menu {
  top: calc(3rem + 1rem);
  top: calc(var(--header-height) + 1rem);
}

/* Active menu link */
.active-link {
  position: relative;
}

.active-link::before {
  content: "";
  position: absolute;
  bottom: -0.75rem;
  width: 40px;
  height: 5px;
  background-color: #161212;
  background-color: var(--title-color);
  border-radius: 10%;
}

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Scroll top */
.scrolltop {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  background: rgba(218, 37, 53, 0.5);
  border-radius: 0.4rem;
  z-index: 10;
  z-index: var(--z-tooltip);
  transition: 0.4s;
  visibility: hidden;
}

.scrolltop:hover {
  background-color: #da2535;
  background-color: var(--first-color);
}

.scrolltop__icon {
  font-size: 1.5rem;
  color: #fefbfb;
  color: var(--body-color);
}

.show-scroll {
  visibility: visible;
  bottom: 1.5rem;
}

/*========== HOME ==========*/
.home__container {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.home__img {
  width: 280px;
  justify-self: center;
}

.home__title {
  font-size: 2rem;
  font-size: var(--biggest-font-size);
  font-weight: 700;
  font-weight: var(--font-bold);
  margin-bottom: 1rem;
  margin-bottom: var(--mb-2);
}

.home__description {
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-3);
}

/*========== BUTTONS ==========*/
.button {
  display: inline-block;
  background-color: #da2535;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  transition: 0.3s;
}

.button:hover {
  background-color: #c42130;
  background-color: var(--first-color-alt);
}

.button-link {
  background: none;
  padding: 0;
  color: #da2535;
  color: var(--first-color);
}

.button-link:hover {
  background-color: transparent;
  color: #c42130;
  color: var(--first-color-alt);
}

/*========== SHARE ==========*/
.share__data {
  text-align: center;
}

.share__description {
  margin-bottom: 1rem;
  margin-bottom: var(--mb-2);
  text-align: justify;
}

.share__img {
  width: 280px;
  justify-self: center;
}

/*========== DECORATION ==========*/
.decoration__container {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.decoration__data {
  text-align: center;
  padding: 1rem 1rem 2rem;
  background-color: var(--container-color__2);
  box-shadow: 0 2px 6px rgba(65, 11, 16, 0.15);
  border-radius: 1rem;
}

.decoration__data:hover {
  box-shadow: 0 3px 12px rgba(65, 11, 16, 0.15);
}

.decoration__img {
  height: 160px;
}

.decoration__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
  color: #5b5757;
}

/*========== ACCESSORIES ==========*/
/* .accessory__container {
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 2rem;
} */

.accessory__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--container-color__2);
  box-shadow: 0 2px 6px rgba(65, 11, 16, 0.15);
  border-radius: 1rem;
}

.accessory__content:hover {
  box-shadow: 0 3px 12px rgba(65, 11, 16, 0.15);
}

.accessory__img {
  width: 80%;
}

.accessory__title,
.accessory__category {
  text-align: center;
}

.accessory__title {
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
}

.accessory__category {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
  color: #8f8a8a;
  color: var(--text-color-light);
}

.accessory__preci {
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  color: #161212;
  color: var(--title-color);
}

.accessory__button {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  font-size: 1.25rem;
  padding: 0.5rem 0.625rem;
  border-radius: 1rem 0 1rem 0;
}

/*========== SEND GIFT ==========*/
.send {
  background-color: #de3b49;
  background-color: var(--first-color-send);
}

.send__title,
.send__description {
  color: #fff;
}

.send__description {
  text-align: center;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
}

.send__direction {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.send__input {
  width: 70%;
  outline: none;
  border: none;
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
}

.send__input::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
}

.send__input:-ms-input-placeholder {
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
}

.send__input::placeholder {
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
}

.send__img {
  width: 280px;
  justify-self: center;
}

/*========== FOOTER ==========*/
.footer__container {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.footer__logo {
  color: #161212;
  color: var(--title-color);
}

.footer__title {
  margin-bottom: 1rem;
  margin-bottom: var(--mb-2);
}

.footer__logo,
.footer__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
}

.footer__link {
  display: inline-block;
  margin-bottom: 0.75rem;
  color: #5b5757;
  color: var(--text-color);
}

.footer__link:hover {
  color: #da2535;
  color: var(--first-color);
}

.footer__social {
  font-size: 1.5rem;
  color: #161212;
  color: var(--title-color);
  margin-right: 1.5rem;
  margin-right: var(--mb-3);
}

.footer__social:hover {
  color: #da2535;
  color: var(--first-color);
}

.footer__copy {
  text-align: center;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: #8f8a8a;
  color: var(--text-color-light);
  margin-top: 4rem;
}

/*========== MEDIA QUERIES ==========*/

/* For full-screen images on small screens */
@media screen and (max-width: 359px) {
  .home__img,
  .share__img,
  .send__img {
    width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .home__container,
  .share__container,
  .send__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .home__container {
    padding: 5rem 0 0;
  }

  .home__img {
    order: 1;
  }

  .section-title-center,
  .share__data,
  .send__description {
    text-align: left;
    text-align: initial;
  }

  .home__img,
  .share__img,
  .send__img {
    width: 100%;
  }

  .share__img {
    order: -1;
  }
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }

  .section {
    padding-top: 7rem;
  }

  .nav {
    height: calc(3rem + 1.5rem);
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__list {
    display: flex;
    align-items: center;
  }

  .nav__item {
    margin-left: 2.5rem;
    margin-left: var(--mb-5);
    margin-bottom: 0;
  }

  .nav__toggle {
    display: none;
  }

  .change-theme {
    position: static;
    position: initial;
    margin-left: 2rem;
    margin-left: var(--mb-4);
  }

  .home__container {
    padding: 7rem 2rem 0;
  }

  .share__container {
    padding: 0 2rem;
  }

  .accessory__container {
    /* grid-template-columns: repeat(3, 224px); */
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    justify-content: center;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }

  .accessory__img {
    width: 80%;
  }

  .accessory__title,
  .accessory__category {
    text-align: left;
    text-align: initial;
  }

  .accessory__button {
    padding: 0.75rem;
  }

  .send {
    background: none;
  }

  .send__container {
    background-color: #de3b49;
    background-color: var(--first-color-send);
    padding: 2rem;
    border-radius: 1.5rem;
  }
}

@media screen and (min-width: 968px) {
  .bd-container {
    margin-left: auto;
    margin-right: auto;
  }

  .home__img,
  .share__img,
  .send__img {
    width: 469px;
  }

  .home__container,
  .share__container,
  .send__container {
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }
}

